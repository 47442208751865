<template>
<div id="container">
  <div v-if="view == 'tutorial'" class="page">
    <h1>Cornhole RPG</h1>
    <h2>TL;DR</h2>
    <p>Gather 3 to 6 players to start.</p>
    <p>Have everyone choose a beanie baby.</p>
    <p>Choose one player to keep track of score with this app.</p>
    <p>Using this app, choose a quest (probably start with 1) and indicate which classes were chosen.</p>
    <p>Cooperate to complete this quest with as high a score as possible.</p>
    <p>Visit <a style="text-decoration: underline;" href="https://docs.google.com/presentation/d/1Y_Mi2tBJTW3qrmUiV2SSuWBtBIAkzn4HM_j3WUY_2kE/edit?usp=sharing">
      The Print and Play</a> if you want to make your own class cards.</p>
    <h2>What is it?</h2>
    <p>Cornhole RPG is a cooperative game for 3 to 6 players where each player throws a beanie baby instead of standard cornhole bag.
    </p>
    <p>Each beanie babie represents a class (thus the RPG 😉).
      And each class has it's own unique throwing style, and a pair of abilities: one for landing on the board and one for getting in the hole.
    </p>
    <p>As a team you will proceed through a series of quests that each take roughly 10 minutes. The quests then to get harder, but each adds it's own different little twist or constraint.
      For instance, in Quest 4 you have to hold someone else's hand while throwing.
    </p>
    <p>A quest is composed of rounds and the number of rounds will depend on the size of the party.
      In each round, everyone throws at the board from 10 feet away (by default).
      You may change the order players throw from round to round.
    </p>
    <p>Depending on how well you score within a quest the team can earn up to three stars (which this app will keep track of),
      and when you complete a quest, it's up to you if want to go for more stars, continue to the next quest (or skip around), or just be satisfied with all the fun you've had.
    </p>
    <p> Good luck! </p>
    <p> - Adrian </p>
    <div class="footer">
      <div class="button" @click="dismissTutorial">Continue</div>
    </div>
  </div>

  <div v-else-if="dialogOptions" class="page">
    <h1>{{ dialogOptions.question }}</h1>
    <div style="display: flex;">
      <div class="big button" @click="onDialogDone(true)">Yes</div>
      <div class="big button" @click="onDialogDone(false)">No</div>
    </div>
  </div>

  <div v-else-if="selection && selection.multiselect" class="select page">
    <h1>{{ selection.message }}</h1>
    <div class="grid">
      <ClassButton v-for="c,i in selection.classes"
        :c="c"
        :selected="selectedClasses.includes(i)"
        @confirm="selectedClasses.toggle(i)"
        :confirmButton="selectedClasses.includes(i) ? 'Remove' : 'Confirm'"
      />
    </div>
    <div class="footer">
      <div class="button" @click="onSelectDone(selectedClasses.map(i => selection.classes))">Continue</div>
    </div>
  </div>
  <div v-else-if="selection && !selection.multiselect" class="select page">
    <h1>{{ selection.message }}</h1>
    <div class="grid">
      <ClassButton v-for="c in selection.classes"
        :c="c"
        @confirm="onSelectDone(c)"
        confirmButton="Confirm"
      />
    </div>
    <div v-if="selection.back" class="footer">
      <div class="button"
        @click="onSelectDone(null)">{{ selection.back }}</div>
    </div>
  </div>

  <div v-else-if="partyInspector" class="page">
    <h1> Your party </h1>
    <div class="grid">
      <ClassButton v-for="c in players" :c="c" infoOnClick/>
    </div>
    <div class="footer">
      <div class="button" @click="partyInspector = false">Back</div>
      <div class="button" @click="respec">Change</div>
    </div>
  </div>
  <div v-else-if="questInspector" class="page">
    <div class="quest-header">
      Quest
      <div class="quest-num">
        <span v-if="questNumber < 10">0</span>{{ questNumber }}
      </div>
    </div>
    <div class="stars">
      <div v-for="(_, i) in 3" class="star">
        <img :src="starUrl(i+1, questNumber)">
        <div>{{ currentQuest.stars[i] }}pt</div>
      </div>
    </div>
    <div>{{ currentQuest.constraint }}</div>
    <div v-if="view=='quest-selection'" class="big button"
      @click="view='class-selection'; questInspector = false">Start Quest</div>

    <div class="footer">
      <div class="button" @click="questInspector = false">Back</div>
      <div v-if="view=='playing'" class="button"
        @click="endGame">Abandon Quest</div>
    </div>
  </div>

  <div v-else-if="view == 'quest-selection'" class="page centered-column">
    <h1>Choose a quest</h1>
    <div v-for="quest,i in quests" class="quest button"
        @click="currentQuest = quest; questInspector = true">
      Quest {{ i + 1 }}
      <img v-for="star in 3" :src="starUrl(star, i+1)">
    </div>
    <div class="footer">
      <div class="button" @click="view='tutorial'">Rules</div>
    </div>
  </div>

  <div v-else-if="view == 'class-selection'" id="class-selection">
    <h1>Who is playing?</h1>
    <div class="grid">
      <ClassButton v-for="c in classes"
        :c="c"
        :selected="players.includes(c)"
        @confirm="players.toggle(c)"
        :confirmButton="players.includes(c) ? 'Remove' : 'Confirm'"
      />
    </div>
    <div class="footer">
      <div class="button" @click="view='quest-selection'">Back</div>
      <div class="button" :class="{disabled: players.length < 3 || players.length > 6}"
        @click="startGame">Continue</div>
    </div>
  </div>

  <div v-else-if="view == 'playing'">
    <div v-if="betweenRounds" class="page">
      <h1>Round Done</h1>
      <h3>
        You earned {{ score - roundStartSnapshot.score }}
        point{{ s(score - roundStartSnapshot.score) }}
      </h3>
      <h3 v-if="roundsLeft - roundStartSnapshot.roundsLeft">
        and {{ roundsLeft - roundStartSnapshot.roundsLeft }}
        round{{ s(roundsLeft - roundStartSnapshot.roundsLeft) }} 
      </h3>
      <div class="spacer"></div>
      <div class="spacer"></div>
      <h2>Pick up your beanie babies</h2>
      <div class="footer">
        <div class="button" @click="finishRound">Continue</div>
      </div>
    </div>
    <div v-else-if="throwingPlayer" class="page">
      <h1>What happened?</h1>
      <div class="centered-column" v-if="throwingPlayer.class == 'Gemini'">
        <div class="button" @click="recordThrow('foul')">Both missed</div>
        <div class="button" @click="geminiExtra = 1; recordThrow('board')">1 board, 1 miss</div>
        <div class="button" @click="geminiExtra = 2; recordThrow('board')">Both on the board</div>
        <div class="button" @click="geminiExtra = 1; recordThrow('both')">1 board, 1 cornhole</div>
        <div class="button" @click="geminiExtra = 1; recordThrow('cornhole')">1 cornhole, 1 miss</div>
        <div class="button" @click="geminiExtra = 2; recordThrow('cornhole')">Both cornhole!</div>
      </div>
      <div class="centered-column" v-else>
        <div class="button" @click="recordThrow('foul')">Missed</div>
        <div class="button" @click="recordThrow('board')">On the board</div>
        <div class="button" @click="recordThrow('cornhole')">Cornhole!</div>
      </div>

      <div class="footer">
        <div class="button" @click="throwingPlayer = null">Back</div>
      </div>
    </div>
    <div v-else-if="selectingThrower">
      <div class="grid">
        <ClassButton v-for="c in remainingThrowers"
          :c="c"
          @confirm="throwingPlayer=c"
          confirmButton="Throw!"
        />
      </div>

      <div class="footer">
        <div class="button" @click="selectingThrower = false">Back</div>
      </div>
    </div>
    <div v-else class="page">
      <div class="left menu">
        <div :class="{ disabled: items.length == 0 }" @click="useItem">
          <div class="count">{{ items.length }}</div>
          <img src="@/assets/slingshot.png">
          <div class="label">Items</div>
        </div>
        <div class="board-to-cornhole" :class="{ disabled: knockedInCandidates.length == 0 }"
            @click="showBoardToCornhole">
          <img src="@/assets/BoardToCornholeSymbol.svg">
          <div class="label">Pushed in</div>
        </div>
      </div>
      <div class="right menu">
        <div class="" @click="editMode = !editMode">
          <img src="@/assets/edit.svg">
          <div class="label">Edit</div>
        </div>
        <div class="" @click="partyInspector = true">
          <img src="@/assets/party.svg">
          <div class="label">Party</div>
        </div>
        <div class="" @click="questInspector = true">
          <img src="@/assets/quest.svg">
          <div class="label">Quest</div>
        </div>
      </div>
      <h1>
        Score
        <img class="score-star" v-for="i in starCount" src="@/assets/goldstar.png">
      </h1>
      <div class="number-widget">
        <div v-if="editMode" class="number-modifier" @click="score -= 1">-</div>
        <div class="number">{{ score }}</div>
        <div v-if="editMode" class="number-modifier" @click="score += 1">+</div>
      </div>
      <h2>Rounds Left</h2>
      <div class="number-widget">
        <div v-if="editMode" class="number-modifier" @click="roundsLeft -= 1">-</div>
        <div class="number">{{ roundsLeft }}</div>
        <div v-if="editMode" class="number-modifier" @click="roundsLeft += 1">+</div>
      </div>
      <div class="status">
        <div v-if="swap">Swap</div>
        <div v-if="abilityMultiplier">{{ abilityMultiplier }}x</div>
        <div v-if="feetCloser">{{ feetCloser }}ft</div>
        <div v-if="plusNumbers">+{{ plusNumbers }}</div>
        <div v-if="streak">↑{{ streak }}</div>
      </div>
      <div v-if="logs.length == 0">
        <div class="log">Using its throwing constraint, have someone throw their beanie baby at the board from 10ft away.</div>
        <div class="log">Then record the result below.</div>
        <div style="font-size: 4em;" class="centered">↓</div>
      </div>
      <div v-else id="logs">
        <div v-for="log in logs" class="log" v-html="log"></div>
      </div>

      <div class="footer">
        <div class="button icon" @click="undo"><img src="@/assets/undo.svg"></div>

        <span v-if="remainingThrowers.length == 0">
          <div v-if="noMoreMoves" class="button" @click="endGame">View summary</div>
          <div v-else-if="roundsLeft == 1" class="button" @click="useItem">Use item</div>
          <div v-else class="button" @click="betweenRounds = true">Finish Round</div>
        </span>
        <div v-else class="button" @click="selectingThrower = true">Record a Throw</div>
      </div>
    </div>
  </div>
  <div v-else-if="view == 'gameover'">
    <h1>Quest Over</h1>
    <h2>You earned {{ starCount }} star{{ s(starCount) }}</h2>
    <img class="gameover-star" v-for="i in starCount" src="@/assets/goldstar.png">

    <div class="footer centered-column">
      <div class="button" v-if="starCount < 3" @click="startGame">Try Again</div>
      <div class="button" @click="currentQuest=null; view='quest-selection'">Select Quest</div>
    </div>
  </div>
</div>
</template>

<script>

import data from "@/data"
import ClassButton from "./components/ClassButton.vue";

let valuesToSave = [
  "score", "roundsLeft", "safetyNet", "swap", "abilityMultiplier", "streak",
  "plusNumbers", "feetCloser", "previousAbility", "roundStartSnapshot", "view",
  "currentQuest", "questNumber",
]
let arraysToSave = [
  "throwHistory", "items", "logs", "players",
]
let objectsToSave = [
  "playerToStatus", "playerToFeetForward",
]

export default {
  name: 'App',
  components: {
    ClassButton,
  },
  data() {
    for (let c of data.classes) {
      c.img = this.getAssetUrl(c.class + ".png")
    }
    return {
      ...data,
      starImages: [
        this.getAssetUrl("star.png"),
        this.getAssetUrl("stars2.png"),
        this.getAssetUrl("stars3.png"),
      ],

      // More premanent state
      currentQuest: null,
      players: [],
      score: 0,
      roundsLeft: 0,
      playerToStatus: {},
      playerToFeetForward: {},
      throwHistory: [],
      items: [],

      // Ability states
      safetyNet: false,
      swap: false,
      abilityMultiplier: 0,
      streak: 0,
      plusNumbers: 0,
      feetCloser: 0,
      previousAbility: null,

      // Inter ability state
      abilityStackCount: 0,
      plus: 0,
      ignoreModifiers: false,
      selectedClasses: [],
      // ^ Class specific ^
      necromancerCache: null,
      geminiExtra: null,

      logs: [],
      previousStates: [],

      view: "tutorial",
      selectingThrower: false,
      throwingPlayer: null,
      betweenRounds: false,
      partyInspector: false,
      questInspector: false,
      editMode: false,

      selection: null,
      dialogOptions: null,

      roundStartSnapshot: null,
    }
  },
  mounted() {
    if (localStorage.getItem("hasSeenTutorial")) {
      this.view = "quest-selection"
      if (location.hostname == "localhost") {
        this.view = "class-selection"
        this.currentQuest = data.quests[1]
      }
    }

    let state = localStorage.getItem("state")
    if (state) {
      this.loadState(JSON.parse(state))
    }
    let previousStates = localStorage.getItem("previousStates")
    if (previousStates) {
      this.previousStates = JSON.parse(previousStates)
    }
  },
  watch: {
    view(val) {
      this.saveState()
      if (val == "quest-selection") {
        this.cleanup()
      }
    },
  },
  computed: {
    prefix() {
      let prefix = ""
      for (let i = 0; i < this.abilityStackCount; i++) {
        prefix += "&nbsp;&nbsp;"
      }
      return prefix
    },
    starCount() {
      let i = 0
      for (; i < 3; i++) {
        if (this.currentQuest.stars[i] > this.score) {
          return i
        }
      }
      return 3
    },
    questNumber: {
      get() {
        return this.quests.indexOf(this.currentQuest) + 1
      },
      set(val) {
        this.currentQuest = this.quests[val - 1]
      },
    },
    noMoreMoves() {
      return this.remainingThrowers.length == 0 && this.roundsLeft == 1 && this.items.length == 0
    },

    remainingThrowers() {
      return this.players.filter(c => this.playerToStatus[c.class] == 'remaining')
    },
    cantThrowPlayers() {
      return this.players.filter(c => this.playerToStatus[c.class] != 'remaining')
    },
    onTheBoard() {
      return this.players.filter(c => this.playerToStatus[c.class] == 'board' || this.playerToStatus[c.class] == 'both')
    },
    inTheHole() {
      return this.players.filter(c => this.playerToStatus[c.class] == 'cornhole' || this.playerToStatus[c.class] == 'both')
    },
    offTheBoard() {
      return this.players.filter(c => this.playerToStatus[c.class] == 'foul')
    },
    knockedInCandidates() {
      return this.onTheBoard.filter(c => c != this.throwHistory[this.throwHistory.length - 1])
    },
  },
  methods: {
    s,
    getAssetUrl(name) {
      if (!name) return
      return require("@/assets/" + name)
    },
    dismissTutorial() {
      this.view = 'quest-selection'
      localStorage.setItem('hasSeenTutorial', true)
    },
    starUrl(index, questNumber) {
      let best = localStorage.getItem("quest" + questNumber)
      let type = "star"
      if (best && parseInt(best) >= index) {
        type = "goldstar"
      }
      return this.getAssetUrl(type + ".png")
    },

    cleanup() {
      this.items = []
      this.streak = 0
      for (let name in this.playerToFeetForward) {
        this.playerToFeetForward[name] = 0
      }
    },
    
    async startGame() {
      this.cleanup()
      this.view='playing'
      switch (this.players.length) {
        case 3:
          this.roundsLeft = 4
          break
        case 4:
          this.roundsLeft = 3
          break
        case 5:
          this.roundsLeft = 2
          this.items = ["slingshot", "slingshot"] // TODO do this for real
          break
        case 6:
          this.roundsLeft = 2
          break
        default:
          console.error("Wat!")
      }
      this.score = 0
      this.playerToStatus = {}
      for (let c of this.players) {
        this.playerToStatus[c.class] = "remaining"
      }
      this.throwHistory = []
      this.logs = []
      this.previousStates = []
      this.roundStartSnapshot = this.roundSnapshot()
      localStorage.setItem("previousStates", "[]")
      this.saveState()
    },
    getState() {
      let state = {}
      for (let key of valuesToSave) {
        state[key] = this[key]
      }
      for (let key of arraysToSave) {
        state[key] = [...this[key]]
      }
      for (let key of objectsToSave) {
        state[key] = {...this[key]}
      }
      return state
    },
    roundSnapshot() {
      let {score, roundsLeft} = this.getState()
      return {score, roundsLeft}
    },

    async respec() {
      this.selectedClasses = this.players.map(player => this.classes.indexOf(player))
      this.players = await this.select({
        message: `What classes are you playing with?`,
        classes: this.classes,
        multiselect: true,
      }).map(i => this.classes[i])
    },

    async recordThrow(outcome) {
      this.previousStates.push(this.getState())
      localStorage.setItem("previousStates", JSON.stringify(this.previousStates))

      this.abilityStackCount = 0
      this.plus = this.plusNumbers
      this.plusNumbers = 0
      this.ignoreModifiers = false

      let name = this.throwingPlayer.class

      if (name == "Gemini") {
        if (outcome == "foul") {
          this.logs.push(`Gemini threw and missed the board.`)
        }
        if (outcome == "board" && this.geminiExtra == 1) {
          this.logs.push(`Gemini threw and one landed on the board.`)
        }
        if (outcome == "board" && this.geminiExtra == 2) {
          this.logs.push(`Gemini threw and both landed on the board.`)
        }
        if (outcome == "cornhole" && this.geminiExtra == 1) {
          this.logs.push(`Gemini threw and one went in the hole.`)
        }
        if (outcome == "cornhole" && this.geminiExtra == 2) {
          this.logs.push(`Gemini threw and both went in the hole.`)
        }
        if (outcome == "both") {
          this.logs.push(`Gemini threw and one landed on the board and one went in the hole.`)
        }
      }
      else {
        if (outcome == "foul") {
          this.logs.push(`${name} threw and missed the board.`)
        }
        if (outcome == "board") {
          this.logs.push(`${name} threw and landed on the board.`)
        }
        if (outcome == "cornhole") {
          this.logs.push(`${name} threw and went in the hole.`)
        }
      }
      this.playerToStatus[this.throwingPlayer.class] = outcome

      if (this.streak > 0) {
        if (outcome == "foul") {
          this.logs.push(`The +${this.streak} has been broken.`)
          this.streak = 0
        } else {
          this.score += this.streak
          this.logs.push(`+${this.streak} from streak.`)
        }
      }

      let type = outcome
      if (this.swap) {
        if (type == 'board') {
          type = 'cornhole'
        } else if (type == 'cornhole') {
          type = 'board'
        }
      }
      this.necromancerCache = null
      this.swap = false
      this.feetCloser = 0

      if (outcome == "board") {
        if (this.questNumber == 16) {
          let success = await this.dialog({question: "Is it hanging off the board?"})
          if (success) {
            let newLogs = await this.triggerAbility(this.throwingPlayer, type)
            this.logs = this.logs.concat(newLogs)
            this.logs.push(`Hanging off edge: activating ability twice.`)
          }
        }
      }
      let newLogs = await this.triggerAbility(this.throwingPlayer, type)
      this.logs = this.logs.concat(newLogs)

      this.throwHistory.push(this.throwingPlayer)
      this.throwingPlayer = null
      this.selectingThrower = false
      this.editMode = false


      if (outcome == "foul") {
        if (this.questNumber == 5) {
          this.feetCloser = 2
          this.logs.push(`Miss can throw from 2ft closer.`)
        }
      }
      if (outcome == "cornhole") {
        if (this.questNumber == 2) {
          let success = await this.dialog({question: "Did they raise their hands in triumph?"})
          if (success) {
            this.score += 1
            this.logs.push(`+1 from triumphant hands.`)
          }
        }
        if (this.questNumber == 8) {
          let success = await this.dialog({question: "Was the throw from 10ft further back?"})
          if (success) {
            this.score += 25
            this.logs.push(`+25 for epic throw.`)
          }
        }
      }

      this.scrollLogsToEnd()
      this.saveState()
    },
    async scrollLogsToEnd() {
      await this.$forceUpdate()
      let logsElem = document.getElementById("logs")
      if (logsElem) {
        logsElem.scrollTop = logsElem.scrollHeight
      }
    },
    saveState() {
      console.log(JSON.stringify(this.getState()))
      localStorage.setItem("state", JSON.stringify(this.getState()))
    },
    loadState(state) {
      for (let key in state) {
        this[key] = state[key]
      }
      this.players = this.players.map(player => {
        for (let c of this.classes) {
          if (c.class == player.class) {
            console.log(c)
            return c
          }
        }
        return {}
      })
    },
    undo() {
      console.log(this.players.length)
      if (this.previousStates.length == 0) {
        this.view = "class-selection"
        return
      }
      this.loadState(this.previousStates.pop())
      this.saveState()
    },
    finishRound() {
      this.roundsLeft -= 1
      this.betweenRounds = false
      for (let c of this.players) {
        this.playerToStatus[c.class] = "remaining"
      }
      this.roundStartSnapshot = this.roundSnapshot()
      this.scrollLogsToEnd()
      this.saveState()
    },
    endGame() {
      this.questInspector = false
      this.roundsLeft = 0
      this.view = 'gameover'
      let previousBest = localStorage.getItem('quest' + this.questNumber)
      if (!previousBest || this.starCount > parseInt(previousBest)) {
        localStorage.setItem('quest' + this.questNumber, this.starCount)
      }
      this.saveState()
    },

    async triggerAbility(c, type) {
      let multiplier = 1
      if (!this.ignoreModifiers && this.abilityMultiplier > 0) {
        multiplier = this.abilityMultiplier
        this.abilityMultiplier = 0
      }
      let suffix = c.class.replace(" ", "")
      let ability = this[type + suffix]
      if (type == 'both') {
        ability = async () => {
          let logs = []
          logs = logs.concat(this.formatAbilityLogs(await this["board" + suffix](), true))
          logs = logs.concat(this.formatAbilityLogs(await this["cornhole" + suffix]()))
          return logs
        }
      }
      if (!ability) return

      let logs = []
      for (let i = 0; i < multiplier; i++) {
        if (type == 'both') {
          logs.push(`${this.prefix}${c.class} board and cornhole abilities activated:`)
        } else {
          logs.push(`${this.prefix}${c.class} ${type} ability activated:`)
        }
        this.abilityStackCount += 1
        let newLogs = this.formatAbilityLogs(await ability())
        this.previousAbility = [c,type]
        this.abilityStackCount -= 1
        logs = logs.concat(newLogs)
      }
      return logs
    },
    formatAbilityLogs(newLogs, ignoreLeadingPrefix) {
      if (typeof(newLogs) == "string") {
        newLogs = [newLogs]
      }
      if (!ignoreLeadingPrefix) {
        newLogs[0] = this.prefix + newLogs[0]
      }
      return newLogs
    },

    select(options) {
      if (options.multiselect && options.classes.length == 0) return []
      this.selection = options
      let self = this
      return new Promise(resolve => {
        self.onSelectDone = c => {
          resolve(c)
          self.selection = null
          self.selectedClasses = []
        }
      })
    },
    dialog(options) {
      this.dialogOptions = options
      let self = this
      return new Promise(resolve => {
        self.onDialogDone = result => {
          resolve(result)
          self.dialogOptions = null
        }
      })

    },

    async useItem() {
      // Only item right now is slingshot
      let c = await this.select({
        classes: this.cantThrowPlayers,
        message: "Which did you pick back up?",
        back: "Cancel",
      })
      if (!c) return
      this.playerToStatus[c.class] = "remaining"
      this.logs.push(`Used slingshot and picked ${c.class} back up.`)
      this.items.pop()
    },
    async showBoardToCornhole() {
      let pushedInClass = await this.select({
        classes: this.knockedInCandidates,
        message: "Did someone get pushed into the hole?",
        back: "Nevermind",
      })
      if (pushedInClass) {
        this.ignoreModifiers = true
        let newLogs = await this.triggerAbility(pushedInClass, "cornhole")
        this.ignoreModifiers = false
        this.logs = this.logs.concat(newLogs)
        this.playerToStatus[pushedInClass.class] = "cornhole"
      }
    },


    async boardCleric() {
      let candidates = this.cantThrowPlayers.filter(c => c.class != "Cleric")
      
      if (candidates.length == 0) return "No beanie babies to pick up."
      let c = await this.select({
        classes: candidates,
        message: "Which did you pick back up?",
      })
      this.playerToStatus[c.class] = "remaining"
      return `Picked ${c.class} back up.`
    },
    cornholeCleric() {
      let points = 2 + this.plus
      this.score += points
      let mult = 2 + this.plus
      this.abilityMultiplier += mult
      return `+${points} point${s(points)} & ${mult}x next ability activations`
    },
    boardSquire() {
      let points = 1 + this.plus
      this.score += points
      return `+${points} point${s(points)}`
    },
    cornholeSquire() {
      let points = 3 + this.plus
      this.score += points
      let feetCloser = 1 + this.plus
      let name = this.throwingPlayer.class
      this.playerToFeetForward[name] = this.playerToFeetForward[name] ?? 0
      this.playerToFeetForward[name] += feetCloser
      return `+${points} points and all future ${name} throws are +${feetCloser}ft closer`
    },
    boardAssassin() {
      let points = 1 + this.plus
      this.score += points
      let mult = 2 + this.plus
      this.abilityMultiplier += mult
      return `+${points} point${s(points)} & ${mult}x next ability activations`
    },
    cornholeAssassin() {
      let points = 1 + this.plus
      this.score += points
      let rounds = 1 + this.plus
      this.roundsLeft += rounds
      return `+${points} point${s(points)} & +${rounds} round${s(rounds)}`
    },
    foulJuggler() {
      let points = 1 + this.plus
      this.score -= points
      return `-${points} point${s(points)}`
    },
    boardJuggler() {
      this.swap = !this.swap
      return `Will swap cornhole and board abilities of next thrower`
    },
    async cornholeJuggler() {
      if (this.throwHistory.length == 0) {
        return 'No previous ability to activate.'
      }
      let c = this.throwHistory.pop()
      let mult = 3 + this.plus
      this.ignoreModifiers = true
      let logs = []
      for (let i =0; i< mult; i++) {
        logs = logs.concat(await this.triggerAbility(c, "board", true))
      }
      this.ignoreModifiers = false
      this.throwHistory.push(c)
      logs.unshift(`Actived previous thrower's board ability ${mult}x`)
      return logs
    },
    boardArcher() {
      let points = 1 + this.plus
      this.score += points
      let feet = 4 + this.plus
      this.feetCloser += feet
      return `+${points} point${s(points)} & next throw ${feet}ft closer`
    },
    cornholeArcher() {
      let points = 2 + this.plus
      this.score += points
      let plusNumbers = 1 + this.plus
      this.plusNumbers += plusNumbers
      return `+${points} points & +${plusNumbers} to numbers next throw`
    },
    boardBerserker() {
      let points = Math.floor((this.score % 100) / 10) + 1 + this.plus
      this.score += points
      return `+${points} point${s(points)}`
    },
    cornholeBerserker() {
      let points = 2 + this.plus
      this.score += points
      let streak = 1 + this.plus
      this.streak += streak
      return `+${points} points & +${streak} streak`
    },
    boardGemini() {
      let points = 1 + this.plus
      if (this.geminiExtra == 2 && this.throwingPlayer.class == "Gemini") {
        points += 1
      }
      this.score += points
      return `+${points} point${s(points)}`
    },
    cornholeGemini() {
      let points = 2 + this.plus
      if (this.geminiExtra == 2 && this.throwingPlayer.class == "Gemini") {
        points += 2
      }
      this.score += points
      return `+${points} point${s(points)}`
    },
    async boardBlueMage() {
      return await this.blueMage("board")
    },
    async cornholeBlueMage() {
      return await this.blueMage("cornhole")
    },
    async blueMage(type) {
      if (this.throwHistory.length == 0) {
        return 'No previous ability to activate.'
      }
      let c = this.throwHistory.pop()
      this.ignoreModifiers = true
      let logs = await this.triggerAbility(c, type)
      this.ignoreModifiers = false
      this.throwHistory.push(c)
      logs.unshift(`Activated previous ${type} ability.`)
      return logs
    },
    async boardNecromancer() {
      let points = 1 + this.plus
      let candidates = this.onTheBoard.filter(c => c != this.throwingPlayer)
      let gemini = null
      for (let c of candidates) {
        if (c.class == 'Gemini') {
          gemini = c
          break
        }
      }
      if (gemini && this.geminiExtra == 2) {
        candidates.push(gemini)
      }
      if (!this.necromancerCache) {
        this.necromancerCache = await this.select({
          message: `Which is ${this.throwingPlayer.class} touching?`,
          classes: candidates,
          multiselect: true,
        })
      }
      let count = this.necromancerCache.length
      points += (2 + this.plus) * count
      this.score += points
      return `+${points} point${s(points)} from touching ${count} other${s(count)}`
    },
    async cornholeNecromancer() {
      let count = 2 + this.plus
      let logs = []
      for (let i = 0; i < count; i++) {
        let candidates = [...this.onTheBoard, ...this.offTheBoard]
        if (candidates.length == 0) {
          logs.push(this.prefix + "No remaining beanie babies to move.")
          count = i
          break
        }
        let c = await this.select({
          classes: candidates,
          message: "Which do you place in the cornhole?"
        })
        if (!c) break

        this.playerToStatus[c.class] = "cornhole"
        logs = logs.concat(await this.triggerAbility(c, "cornhole"))
      }
      logs.unshift(`Placed ${count} beanie babies in the cornhole.`)
      return logs
    },
    boardWarrior() {
      let points = 2 + this.plus
      this.score += points
      return `+${points} points`
    },
    cornholeWarrior() {
      let points = 3 + this.plus
      if (this.inTheHole.filter(c => c != this.throwingPlayer).length == 0) {
        points += 2 + this.plus
      }
      this.score += points
      return `+${points} points`
    },
    async boardMonk() {
      let candidates = this.onTheBoard.filter(c => c != this.throwingPlayer)
      if (candidates.length == 0) return "No other beanie babies on the board."
      let c = await this.select({
        classes: candidates,
        message: "Which board ability do you activate?",
      })
      return await this.triggerAbility(c, "board")
    },
    async cornholeMonk() {
      let mult = 2 + this.plus
      let [c, type] = this.previousAbility
      this.ignoreModifiers = true
      let logs = []
      for (let i =0; i< mult; i++) {
        logs = logs.concat(await this.triggerAbility(c, type, true))
      }
      this.ignoreModifiers = false
      this.throwHistory.push(c)
      logs.unshift(`Actived previous ability ${mult}x`)
      return logs
    },
  }
}

Array.prototype.remove = function(elem) {
  let index = this.indexOf(elem)
  if (index >= 0) {
    this.splice(index, 1)
  }
  // Just ignore if not in array
}
Array.prototype.toggle = function(elem) {
  if (this.includes(elem)) {
    this.remove(elem)
  } else {
    this.push(elem)
  }
}
function s(num) {
  return num == 1 ? "" : "s"
}
</script>

<style>

html, body, #app {
  width: 100%;
  min-height: 100vh;
}
body {
  margin: 0;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  flex-direction: column;
  align-items: center;
  --bg-color: #080808;
}
a {
  text-decoration: none;
}
a, a:visited {
  color: inherit;
}

h1 {
  font-size: 2.5rem;
  width: 100%;
  box-sizing: border-box;
  text-align: center;
}
h2 {
  font-size: 1.8rem;
  width: 100%;
  box-sizing: border-box;
  text-align: center;
  margin-top: 0;
}
h3 {
  font-size: 1.5rem;
  width: 100%;
  box-sizing: border-box;
  text-align: center;
}
.spacer {
  height: 2rem;
}
.centered {
  width: 100%;
  display: flex;
  justify-content: center;
}

#container {
  width: 100%;
  max-width: 700px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #121212;
  font-weight: 700;
  padding-bottom: 5rem;
}
.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  padding: 0.5rem;
  justify-content: space-evenly;
  background-color: white;
}

.page {
  width: 100%;
  box-sizing: border-box;
  padding: 0.5rem;
}
.centered-column {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.button {
  height: 2.5rem;
  width: fit-content;
  display: flex;
  align-items: center;
  border: 0.1rem solid black;
  border-radius: 0.5rem;
  padding: 0.25rem 1rem;
  margin: 0.5rem 0;
  text-align: center;
  font-size: 2rem;
  cursor: pointer;
  background: white;
  color: #121212;
  position: relative;
  box-shadow: 0 0.2rem 0.125rem 0.05rem rgba(0, 0, 0, 0.3);
}
.button.disabled {
  background-color: #ccc;
  color: #666;
  pointer-events: none;
}

.button:hover {
  color: #555;
}
.button:active {
  box-shadow: 0 0 0.25rem 0.1rem rgba(0, 0, 0, 0.3);
  color: #666;
  transform: translateY(0.2rem);
}

.big.button {
  margin: 2rem auto 0;
  font-size: 3.2rem;
  height: 3.8rem;
}

.icon.button {
  padding: 0.25rem;
}
.icon.button img {
  width: 2.5rem;
  height: 2.5rem;
}
.icon.button.disabled img {
  opacity: 0.5;
}

.quest.button img {
  height: 24px;
  width: 24px;
  margin: 0 2px;
}

.quest-card {
  width: 100%;
  padding: 2rem;
  box-sizing: border-box;
  font-size: 1.5rem;
}
.quest-num {
  font-size: 5rem;
}
.quest-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-size: 2rem;
}
.stars {
  display: flex;
  justify-content: center;
  margin-bottom: 3rem;
}
.star {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 5rem;
}
.star img {
  height: 48px;
}

.grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.left {
  left: 0;
}
.right {
  right: 0;
}
.menu {
  position: absolute;
  top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 5rem;
  z-index: 1;
}
.menu > div {
  width: 2.5rem;
  padding: 1rem;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.menu > .disabled {
  pointer-events: none;
  opacity: 0.3;
}
.menu > div img {
  width: 2.5rem;
}
.menu > .board-to-cornhole {
  padding: 0.5rem 1.5rem;
  width: 2rem;
}
.menu > .board-to-cornhole img {
  width: 2rem;
}
.menu > div .count {
  position: absolute;
  left: 0.2rem;
  top: 0.5rem;
}
.menu .label {
  font-size: 0.7rem;
  margin: 0 -1rem;
}

.number-widget {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: -2rem 0 1rem;
}
.number-modifier {
  border: 0.1rem solid black;
  border-radius: 25%;
  width: 1.2rem;
  height: 1.2rem;
  margin: 0 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.number {
  font-size: 3rem;
}

.score-star {
  width: 2rem;
  position: relative;
  top: 0.1rem;
  margin: 0.1rem;
}
.gameover-star {
  width: 6rem;
  margin: 0.5rem;
}

.status {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
}
.status > div {
  margin: 0 0.3rem;
}

#logs {
  height: 40vh;
  overflow: scroll;
}

</style>
