module.exports = {
  quests: [
    {
      stars: [5,10,15],
      constraint: "Try to get at least 5 points.  (or 10 points for two stars or 15 points for three).",
    },
    {
      stars: [8,15,25],
      constraint: "When someone cornholes, if they immediately raise their hands in triumph, gain +1 point.",
    },
    {
      stars: [5,10,15],
      constraint: "Everyone must do a 360 degree spin before throwing.",
    },
    {
      stars: [8,15,25],
      constraint: "Everyone must hold hands with someone else while throwing.",
    },
    {
      stars: [10,20,30],
      constraint: "If a throw misses the board, the next throw is 2 feet closer. This does not stack.",
    },
    {
      stars: [15,25,40],
      constraint: "Use the throwing constraint of the previous thrower. The first throw can be done with no constraint.",
    },
    {
      stars: [10,15,25],
      constraint: "You have 5 minutes to complete this quest, starting from the first throw.",
    },
    {
      stars: [20,35,50],
      constraint: "You may throw from 10 feet further and if you trigger a cornhole ability, you gain an extra +25 points.",
    },
    {
      stars: [15,20,30],
      constraint: "After the first throw, only the person holding the score keeping card or app may speak.",
    },
    {
      stars: [10,25,35],
      constraint: "Players don’t choose their own classes. Instead, at the beginning of each round, shuffle all class cards together and assign classes at will.",
    },
    {
      stars: [15,20,30],
      constraint: "Someone else must toss your beanie baby to you right before you throw it.",
    },
    {
      stars: [15,25,35],
      constraint: "At the beginning of each round, shuffle all players’ cards together and draw one. That class must throw first.",
    },
    {
      stars: [18,33,45],
      constraint: "You may respec in the middle of this quest. But you can’t choose a class that’s already been thrown this round.",
    },
    {
      stars: [20,30,45],
      constraint: "If you push a beanie baby off the board, place it in the hole instead.",
    },
    {
      stars: [10,17,25],
      constraint: "Play with the cornhole board sideways.",
    },
    {
      stars: [25,35,50],
      constraint: "Beaning babies hanging over the edge of the board activate their board ability 2x instead.",
    },
    {
      stars: [25,35,50],
      constraint: "You may throw from 4 feet closer, but you must throw a cornhole to activate any abilities.",
    },
    {
      stars: [20,30,45],
      constraint: "Every round Choose randomly from quests 4, 6, 9, 10, and 12 and play that round with the rule described in that quest.",
    },
    {
      stars: [15,25,35],
      constraint: "If you throw foul, end the quest instantly. You still get whatever score you had.",
    },
    {
      stars: [25,40,55],
      constraint: `Before every throw, the thrower must say “Let me show you how it’s done.”`,
    },
  ],
  classes: [
    {
      name: "Fetch",
      class: "Cleric",
      throwingConstraint: "underhand",
      boardAbility: "1 non-cleric that already threw this round may pick up their beanie baby and can throw again at any time this round",
      cornholeAbility: "+2 points & next thrower activates ability 2x",
    },
    {
      name: "Paddi",
      class: "Squire",
      throwingConstraint: "may lunge in front of the line",
      boardAbility: "+1 point",
      cornholeAbility: "+3 points & all throws for this beanie baby are 1 foot closer for the rest of this quest",
    },
    {
      name: "Ringo",
      class: "Assassin",
      throwingConstraint: "over shoulder, looking away",
      boardAbility: "+1 point & next thrower activates ability 2x",
      cornholeAbility: "+1 point & gain +1 round",
    },
    {
      name: "Strech",
      class: "Juggler",
      throwingConstraint: "hold your throwing hand foot",
      foulAbility: "Lose 1 point",
      boardAbility: "Swap cornhole and board abilities for next thrower",
      cornholeAbility: "Activate Board Ability of previous thrower 3x",
    },
    {
      name: "Ears",
      class: "Archer",
      throwingConstraint: "two-handed",
      boardAbility: "+1 point & next thrower throws from 4 feet closer",
      cornholeAbility: "+2 points & +1 to all numbers in abilities activated by next thrower",
    },
    {
      name: "Pouch",
      class: "Berserker",
      throwingConstraint: "running jump",
      boardAbility: "Add the tens digit of current score +1 points",
      cornholeAbility: "+2 points & all throws get +1 point until someone misses",
    },
    {
      name: "Blizzard and Stripes",
      class: "Gemini",
      throwingConstraint: "both at same time",
      boardAbility: "+1 point if just one on the board +2 points if both on the board",
      cornholeAbility: "+2 points if just one cornholes +4 points if both cornhole",
    },
    {
      name: "Crunch",
      class: "Blue Mage",
      throwingConstraint: "previous constraint",
      boardAbility: "Activate Board Ability of previous thrower",
      cornholeAbility: "Activate Cornhole Ability of previous thrower",
    },
    // {
    //   name: "Waddle",
    //   class: "Mage",
    //   throwingConstraint: "feet facing backwards",
    //   boardAbility: "+1 point & for every ability activated by next thrower, +1 points",
    //   cornholeAbility: "+2 points & if you have no items draw 2 items, otherwise draw 1 item",
    // },
    // {
    //   name: "Spike",
    //   class: "Geomancer",
    //   throwingConstraint: "one hand touching the ground",
    //   boardAbility: "+1 point & if the next thrower throws foul, activate that thrower’s board ability",
    //   cornholeAbility: "+2 points & 2x the effect of an item if used immediately. If you have no items, draw one and use it",
    // },
    // {
    //   name: "Daisy",
    //   class: "Merchant",
    //   throwingConstraint: "piggy backed on someone’s back",
    //   boardAbility: "Lose 1 point & draw 1 item",
    //   cornholeAbility: "Lose 2 points & draw 3 items",
    // },
    // {
    //   name: "Rocket",
    //   class: "Sapper",
    //   throwingConstraint: "under someone else’s leg",
    //   boardAbility: "+2 points & discard a random item if you have any",
    //   cornholeAbility: "+3 points & next thrower may throw from 4 feet further back to activate ability 2x",
    // },
    {
      name: "Spinner",
      class: "Necromancer",
      throwingConstraint: "non-dominant hand",
      boardAbility: "+1 point & +2 points for every beanie baby the thrower is touching",
      cornholeAbility: "Place 2 previously thrown beanie babies that aren’t in the cornhole directly in the cornhole",
    },
    {
      name: "Ants",
      class: "Warrior",
      throwingConstraint: "overhand",
      boardAbility: "+2 points",
      cornholeAbility: "+3 points & +2 points if no other beanie baby is in the cornhole",
    },
    {
      name: "Bongo",
      class: "Monk",
      throwingConstraint: "sitting down",
      boardAbility: "Activate the Board Ability of a beanie baby that’s currently on the board",
      cornholeAbility: "Activate the previously activated ability 2x",
    },
    {
      name: "Missing No.",
      class: "Custom",
      throwingConstraint: "???",
      boardAbility: "???",
      cornholeAbility: "???",
    },
  ],
  items: [],
}